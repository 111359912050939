import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import Collaboration from "../pages/Collaboration";
import "../index.css";
import { ProgressBar } from "react-loader-spinner";
import { app } from "@microsoft/teams-js";
import * as eventBus from '@dewdrops/event-bus';
import { callGetDetails, getAzureEntity, getSelectedEnv } from "./Common.tsx";

try {
  app.initialize();
} catch (err) { }
function TestCollab() {
  const [callback, setCallBack] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [poNumber, setPoNumber] = useState("");
  const [typeOfDoc, setTypeOfDoc] = useState("");
  // useEffect(()=>{
  //    app.getContext().then((context) => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //    let ponumberfromSubPage = context?.page?.subPageId.split("|");
  //    console.log("ponumberfromSubPage",ponumberfromSubPage);
  //    const poNumber =ponumberfromSubPage[0]
  //    const typeOfDocstatic = ponumberfromSubPage[1]
  //    console.log("ponumber",poNumber)
  //    console.log("typeOfDocstatic",typeOfDocstatic)

  //    if(poNumber && typeOfDocstatic)
  //    {
  //     setPoNumber(poNumber)
  //     setTypeOfDoc(typeOfDocstatic)
  //     setMyContext(context)
  //    }
  //   });
  // },[])

  const initializeEventBus = (poNumber, typeOfDocstatic, aadObjectId, rosterData, userRegionTenant, getDetailsData) => {

    const getDetails = getDetailsData.data;
    getDetails.userDetails.locale = rosterData.langCode
    console.log({

      poId: poNumber,
      product: typeOfDocstatic,
      aadObjectId: aadObjectId,
      region: process.env.REACT_APP_bot

    })

    console.log({

      userDetails: getDetails.userDetails,
      userPreference: {
        timeZone: 'Etc/UTC',
        dateFormat: 'dd/MM/yyyy',
        timeFormat: '24 Hours',
        locale: rosterData.langCode
      },
      userSetting: getDetails.userSetting,
      localeDetails: getDetails.localeDetails,

    })

    eventBus.init({
      merlinDetails: () => {
        return {
          poId: poNumber,
          product: typeOfDocstatic,
          aadObjectId: aadObjectId,
          region: process.env.REACT_APP_bot
        }
      },
      getUserDetails: () => {
        return {
          userDetails: getDetails.userDetails,
          userPreference: {
            timeZone: 'Etc/UTC',
            dateFormat: 'dd/MM/yyyy',
            timeFormat: '24 Hours',
            locale: rosterData.langCode
          },
          userSetting: getDetails.userSetting,
          localeDetails: getDetails.localeDetails,
        }
      }

    }).then((res) => {
      console.log("Response", res)
      setCallBack(true);
    }).catch((err) => {
      console.log("Error", err)
      setCallBack(true);
    })
  }

  const disablePopupImg = require("../images/popups.png"); // with require
  const msteamsIcon = require("../images/msteamsicon.png");

  useEffect(() => {
    try {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.authentication
          .authenticate({
            url: `${window.location.origin}/collabAssign`,
            isExternal: false,
            width: 600,
            height: 535,
          })
          .then((result) => {
            setLoadingImg(false);
            console.log("Login succeeded: " + result);
          })
          .catch(async () => {
            setLoadingImg(false);
            console.log("window close");
            let ponumberfromSubPage;
            let poNumber;
            let typeOfDocstatic;
            app.getContext().then(async (context) => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              ponumberfromSubPage = context?.page?.subPageId.split("|");
              console.log("ponumberfromSubPage", ponumberfromSubPage);
              poNumber = ponumberfromSubPage[0]
              typeOfDocstatic = ponumberfromSubPage[1]
              setPoNumber(poNumber);
              setTypeOfDoc(typeOfDocstatic)
              console.log("ponumber", poNumber)
              console.log("typeOfDocstatic", typeOfDocstatic)
              console.log("accountname", process.env.accountName);
              const aadObjectId = context?.user?.id;
              console.log("aadObjectId", aadObjectId);

              await callToTable(poNumber, typeOfDocstatic, aadObjectId)

            });

          });
      });
      //  }
    } catch (err) { }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const poNumber = "123";
  //   const typeOfDocstatic = "123";
  //   const aadObjectId = "86b2694a-0ad6-44f7-902c-8c071cfa8855"
  //   callToTable(poNumber, typeOfDocstatic, aadObjectId)
  // }, [])

  const callToTable = async (poNumber, typeOfDocstatic, aadObjectId) => {
    const rosterData = await getAzureEntity(aadObjectId);
    console.log("rosterData", rosterData)
    if (rosterData !== null) {
      const userRegionTenant = await getSelectedEnv(aadObjectId);
      console.log("userRegionTenant", userRegionTenant)

      if (userRegionTenant !== null) {
        const getDetailsData = await callGetDetails(rosterData, userRegionTenant)
        console.log("getDetailsData", getDetailsData)

        initializeEventBus(poNumber, typeOfDocstatic, aadObjectId, rosterData, userRegionTenant, getDetailsData)
      }
    }
  }

  return (
    <div>
      {loadingImg && (
        <div className="parent">
          <img
            className="msteamsIcon"
            src={msteamsIcon}
            alt="Microsoft teams"
          />
          <div>
            {loadingImg ? (
              <span className="progressBar">
                <ProgressBar
                  height="50"
                  width="50"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{ width: "180px" }}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#7B83EB"
                  barColor="#464EB8"
                />
                <p style={{ margin: 0, fontSize: 12, marginLeft: 280 }}>
                  Loading App .....
                </p>
              </span>
            ) : null}
          </div>
          <img className="disablePopupImg" src={disablePopupImg} alt="Popup" />
        </div>
      )}
      {!loadingImg && callback && <Collaboration poNumber={poNumber} typeOfDoc={typeOfDoc} />}
    </div>
  );
}

export default TestCollab;
