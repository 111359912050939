import { useCookies } from "react-cookie";
import authStore from "../Keys";
import Cookies from "js-cookie";
import { getCall } from "../Api/services";
import { app } from "@microsoft/teams-js";
import { ColorRing } from "react-loader-spinner";
import { getMstKeys } from "../Common";
try {
  app.initialize();
} catch (err) {}
let userObjectId;
let mstKeys;
function CollabAssign() {
  const [, setCookie] = useCookies(["SAAS_COMMON_BASE_TOKEN_ID"]);
  app.getContext().then((context) => {
    userObjectId = context?.user?.id;
    if (userObjectId !== null && userObjectId !== undefined) {
      getAzureEntity();
    }
  });

  const getAzureEntity = async () => {
    console.log("accountname", process.env.accountName);
    const authObject = new authStore();
    let config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      const zauthToken = response.data && response.data.zycusAuthToken;
      mstKeys = response?.data?.mstKeyJson;
      mstKeys = getMstKeys(mstKeys);
      let name = "SAAS_COMMON_BASE_TOKEN_ID",
        value = zauthToken,
        pathe = "/",
        domain = ".zycus.com",
        //domain = "localhost",
        ATS_getExpire = function () {
          return "ATS_getExpire";
        };

      setCookie("SAAS_COMMON_BASE_TOKEN_ID", value, {
        path: pathe,
        domain: domain,
        sameSite: "none",
        httpOnly: false,
        secure: true,
      });
      console.log("setcookie executed");
      let curCookie =
        name +
        "=" +
        value +
        ", expires=" +
        ATS_getExpire() +
        ",path=" +
        pathe +
        ", domain=" +
        domain;
      Cookies.set("session", curCookie, {
        domain: ".zycus.com",
        sameSite: "none",
        httpOnly: false,
        secure: true,
      });
      console.log("cookie", window.document.cookie);
      document.cookie = curCookie;
      window.document.cookie = `SAAS_COMMON_BASE_TOKEN_ID=${zauthToken}`;
      console.log("cookie", window.document.cookie);
      let authorizeEndpoint = `${window.location.origin}/CollabMessage`;
      console.log("authorizeEndpoint", authorizeEndpoint);
      window.location.assign(authorizeEndpoint);
    }
  };
  return (
    <div>
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#7B83EB", "#505AC9", " #464EB8", "#7B83EB", "#7B83EB"]}
      />
      <p>{mstKeys && mstKeys.MST_Hang_on_while_we_get_things_set_up_for_you}</p>
    </div>
  );
}

export default CollabAssign;
